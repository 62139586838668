<template>
  <ul class="social">
    <li v-for="(social, i) in socialList" :key="i">
      <a :href="social.link" target="_blank" rel="noreferrer"
        ><img class="svg" :src="social.src" alt="social"
      /></a>
    </li>
  </ul>
  <!-- End. social -->
</template>

<script>
  export default {
    data() {
      return {
        socialList: [
          {
            link: "https://www.linkedin.com/in/mert-akturk/",
            src: require("../assets/img/svg/social/linkedin.svg"),
          },
          {
            link: "https://github.com/nurovic",
            src: require("../assets/img/svg/social/github.svg"),
          },
          {
            link: "mailto:mertnurovic@gmail.com",
            src: require("../assets/img/svg/mail.svg"),
          },
        ],
      };
    },
  };
</script>

<style lang="scss" scoped></style>
