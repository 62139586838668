<template>
  <div class="portfolio_tm_home">
    <div class="home_content">
      <div class="avatar">
        <div
          class="image avatar_img"
          :style="{ backgroundImage: 'url(' + src1 + ')' }"
        ></div>
      </div>
      <!-- End .avatar -->
      <div class="details">
        <h3 class="name">Mert Aktürk</h3>
        <p class="job">
          A self-taught fullstack developer with experience in building and designing web applications. Skilled in HTML, CSS, JavaScript, and experince in frontend libraries and frameworks such as Vue and React, as well as backend technologies like Node.js.
        </p>

        <Social />
      </div>
      <!-- End .details -->
    </div>
  </div>
</template>

<script>
  import Social from "./Social";
  export default {
    components: {
      Social,
    },
    data() {
      return {
        src1: require("../assets/img/slider/3.jpg"),
      };
    },
  };
</script>

<style lang="scss"></style>
